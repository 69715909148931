import { dqs, dqa } from "./ui.mjs";
import { EditCases } from "./format.mjs";
import { getTextWidth, removePlural } from "../modules/format.mjs";
import { pageIcons } from "../modules/local.mjs";
import CurrencyController from "./currency.mjs";
import { CalloutController, ValidationController } from "./forms.mjs";
import { OptionsBuilder, TableEditor } from "./forms.mjs";
import { setScrollPosition, EditCssVariants } from "./ui.mjs";
import WorkspaceController from "../realm/workspace.mjs";
import { ObjectId } from "../realm/auth.mjs";

const { camelCase, kababCase, sentanceCase } = EditCases;
const { formatCurrency, setCurrency, convertCurrency } = CurrencyController;
const { getCssVariant } = EditCssVariants;
const { updateItem } = WorkspaceController;

export default class TableBuilder {
  constructor({
    table,
    type,
    collection,
    fields,
    activeFields,
    values,
    options,
    conditions,
    homeCurrency,
    exchangeRates,
    limit,
  } = {}) {
    //Params for lists & tables:
    this.table = table;
    this.type = type;
    this.collection = collection;
    this.fields = fields;
    this.activeFields = activeFields;
    this.values = values;
    this.options = options;
    this.conditions = conditions;
    this.homeCurrency = homeCurrency;
    this.exchangeRates = exchangeRates;
    this.limit = limit;
  }

  //Main table on page (Product or Components Table):
  insertTable() {
    //Creates Headders:
    this.insertHeaders();
    //Creates Rows:
    this.insertRows(this.values);
  }

  //Creates and then inserts the Table Headers into the DOM:
  insertHeaders() {
    let cells = [];

    this.activeFields.forEach((field) => {
      field = kababCase(field);
      const key = camelCase(field);
      const width = this.setRowWidth(key, this.table, 1);
      let minWidth;

      if (width > 0) minWidth = `style="min-width: ${width}px"`;
      cells.push(
        `<th id='${field}-col' ${minWidth} scope='col'>${this.formatHeaders(field)}</th>`,
      );
    });

    let row = ["<tr id='table-header'>", ...cells, "</tr>"];

    //Inserts the Headers into the table:
    this.table.innerHTML = row.join(" ");
  }

  formatHeaders(field) {
    //Adapts field names to match table headers:
    let name = "";

    switch (field) {
      case "component-costs":
        name = "<span class='cross'>&#10010;</span>&nbsp;Costs";
        break;
      case "postal-code":
        name = "Postcode";
        break;
      case "landed-cost":
        name = "<span class='arrow'>↯</span> Landed";
        break;
      case "edit":
        name = "<b>...</b>";
        break;
      default:
        name = sentanceCase(field);
        break;
    }
    return name;
  }

  insertRows(rowValues, position = null) {
    const systemFields = this.fields?.system;

    rowValues.forEach((item, index) => {
      //Inserts each ROW into Table/List:
      let rowIndex = position;
      if (!rowIndex) rowIndex = index + 1;

      const row = this.table.insertRow(rowIndex);
      let cellsData = Object.assign({}, item);

      switch (this.type) {
        case "table":
          cellsData = setCurrency(this.homeCurrency, cellsData);
          break;
        case "list":
          const fieldsToConvert = ["unitCost"];

          cellsData = convertCurrency(
            cellsData,
            this.exchangeRates,
            fieldsToConvert,
          );

          for (const key of fieldsToConvert) {
            cellsData[key] = formatCurrency(this.homeCurrency, cellsData[key]);
          }

          break;
      }

      //Adds the cells into each row:
      this.activeFields.forEach((field) => {
        const key = camelCase(field);
        field = kababCase(field);

        let cellData = cellsData[key];
        let classList = `${field}-cell`;
        let contextual = "";

        switch (key) {
          case "colors":
            cellData = isColorCell(cellData);
            break;
          case "supplierType":
            cellData = isSupplierTypeCell(cellData);
            break;
          case "leadTime":
            contextual = isLeadTimeCell(cellData);
            break;
          case "country":
          case "sizes":
          case "supplier":
            cellData = cellData.value;
            break;
          case "edit":
            cellData = isEditCell(this.fields);
            break;
          case "add":
            cellData = isAddComponentCell(rowIndex);
            break;
          case "qty":
            cellData = isComponentQtyCell(rowIndex);
            break;
          case "addressTwo":
            cellData = cellData || "...";
            break;

          default:
            const systemField = systemFields?.includes(field);
            if (systemField) {
              cellData = `<span class="immutable-item">${cellData}</span>`;
              classList = `${field}-cell system`;
              contextual = `<span class="immutable-icon">${pageIcons.lockIcon}</span>`;
            }
            break;
        }

        //Inserts each CELL into Table/List:
        row.insertAdjacentHTML(
          "beforeend",
          `<td class="${classList}" data-key="${field}">${cellData}${contextual}</td>`,
        );
      });

      //Adds the mongodb id to each row:
      row.setAttribute("data-_id", `${cellsData._id.toString()}`);

      this.insertColorMessages(row);
      this.handleRowControls(row);
    });
  }

  setRowWidth(key, row) {
    let values = this.options[key] || [];
    const getLongest = (arr) =>
      arr.reduce((a, b) => (a.length > b.length ? a : b));
    let width = 0;
    if (values.length) {
      const longest = getLongest(values);
      const value = longest?.value || longest;
      width = getTextWidth(row, value);
    }
    return width;
  }

  //Inserts color names into table:
  insertColorMessages(row) {
    const colorsCell = row.querySelector(".colors-cell");

    if (colorsCell) {
      const colors = colorsCell.querySelectorAll(".color-box");

      colors.forEach((colorSpan) => {
        let color = colorSpan.id.replace("color-", "");

        const options = {
          position: "center",
          element: colorSpan,
          message: { type: "color", value: sentanceCase(color) },
          eventTypes: ["mouseover", "mouseleave", "click"],
        };

        const calloutMesage = new CalloutController(options);

        calloutMesage.insertCalloutMessage();
        calloutMesage.setCalloutEvent();
      });
    }
  }

  handleRowControls(row) {
    const compBtn = row.querySelector(".comp-btn");
    const editBtn = row.querySelector(".edit-btn");
    const delBtn = row.querySelector(".del-btn");

    const sveBtn = row.querySelector(".sve-btn");
    const cnxBtn = row.querySelector(".cnx-btn");

    const loadComponents = (e) => this.handleList(e, row);
    const loadUpdate = (e) => this.insertUpdateRow(e, row);
    const loadDelete = (e) => this.deleteRow(e, row);

    const loadSave = (e) => this.saveUpdatedRow(e, row);
    const loadCancel = (e) => this.cancelRow(e);

    if (editBtn) {
      editBtn.removeEventListener("click", loadUpdate);
      editBtn.addEventListener("click", loadUpdate);
      delBtn.addEventListener("click", loadDelete);

      sveBtn.addEventListener("click", loadSave);
      cnxBtn.addEventListener("click", loadCancel);
    }
    if (compBtn) {
      compBtn.addEventListener("click", loadComponents);
    }
  }

  //On Save/Cancel displays the correct row buttons:
  handleRowBtns(row) {
    //De-activates edit buttons:
    const rowBtns = row.querySelectorAll(".row-btn");
    rowBtns.forEach((btn) => btn.classList.toggle("active"));

    row.classList.toggle("diag-lines");
    row.classList.toggle("editing");
  }

  //Attached to eventListner:
  insertUpdateRow(e, row) {
    const disableHover = () => {
      //Removes color hoverover effect:
      const callout = row.querySelector(".callout-wrapper");
      if (callout) {
        callout.classList.remove("active");
      }

      const colorBoxs = row.querySelectorAll(".color-box");
      colorBoxs.forEach((colorBox) => {
        colorBox.classList.add("disable-hover");
      });
    };

    const dataIndex = row.rowIndex - 1;

    const editorParams = {
      wrapper: row,
      key: "updateItem",
      collection: this.collection,
      fields: this.fields,
      activeFields: this.activeFields,
      options: this.options,
      values: this.values[dataIndex],
      conditions: this.conditions,
      validate: this.validate,
    };

    const editTable = new TableEditor(editorParams);
    editTable.insertRowOptions();

    disableHover();
    this.handleRowBtns(row);

    const inputs = row.querySelectorAll("INPUT.capture");
    const addFocus = inputs[0];
    addFocus.focus();
    const end = addFocus.value.length;
    addFocus.setSelectionRange(end, end);

    const validateParams = {
      form: row,
      inputs: inputs,
      validate: "any",
      conditions: this.conditions,
    };

    const validateInputs = new ValidationController(validateParams);
    validateInputs.handleValidation({ position: "inline" });
  }

  //ALL LIST METHODS:
  //Inserts the doc to be edited into the component list:
  insertListModal({ itemKey, itemFields } = {}) {
    const dynamicModal = dqs("#dynamic-modal");
    const listId = kababCase(this.collection);
    const ModalTitle = this.collection.toUpperCase();

    itemKey = kababCase(itemKey);

    //Inserts the list modal:
    dynamicModal.insertAdjacentHTML(
      "beforeend",
      `<div id="${listId}-list-modal" class="form-modal modal large-modal list-modal">
        <div class="modal-inner-wrapper">
          <div class="modal-title-grid">
            <h3>ADD ${ModalTitle}</h3>
            <button aria-label="close-modal" class="close-button" tabindex="-1">${pageIcons.cnxIcon}</button>
          </div>

          <div class="modal-outer-wrapper">
            <div id="${itemKey}-info" class="details-wrapper info"></div>
            <div id="${itemKey}-cost" class="details-wrapper cost"></div>
          </div>

          <div class="modal-outer-wrapper"><div class="list-controls">
            <div id="list-query-wrapper" class="query-wrapper list"></div>
          </div></div>

          <div class="modal-outer-wrapper list-wrapper">
            <table id="${listId}-list"><tbody id="import-${listId}-list"></tbody></table>
          </div>
        </div>
        <hr />
      </div>`,
    );

    const listModal = dynamicModal.querySelector(`.list-modal`);

    const insertDetails = () => {
      itemFields.forEach((field) => {
        let name = this.formatHeaders(field);
        let fieldType = field.includes("cost") ? "cost" : "info";

        const listDetails = listModal.querySelector(`#${itemKey}-${fieldType}`);

        listDetails.insertAdjacentHTML(
          "beforeend",
          `<div class="details-group ${fieldType}">
            <div class="group-item parent"><a>${name}</a></div>
            <div data-field="${field}" class="group-item child"> </div>
          </div>`,
        );
      });
    };

    insertDetails();

    const closeButton = listModal.querySelector(".close-button");
    closeButton.addEventListener("click", async (e) => {
      const { currentTarget } = e;

      const closeModal = () => {
        listModal.classList.remove("active");
        dynamicModal.classList.remove("active");
      };

      //Resets all list inputs:
      this.constructor.resetList(listModal);
      closeModal();

      const listWrapper = dqs(".list-wrapper");
      setScrollPosition({ el: listWrapper });

      const { setRowLimit } = await import("../js/user/items.js");
      setRowLimit(11);

      const body = currentTarget.closest("BODY");

      const enableScroll = () => {
        const passive = { passive: false };
        body.removeEventListener("DOMMouseScroll", this.preventScroll, false);
        body.removeEventListener("wheel", this.preventScroll, passive);
        body.removeEventListener("touchmove", this.preventScroll, passive);
      };

      enableScroll();
    });
  }

  static resetList(wrapper) {
    const searchInput = wrapper.querySelector("INPUT[type=search]");
    const isActive = Array.from(wrapper.classList);

    if (isActive.includes("active")) {
      searchInput.value = "";
      searchInput.dispatchEvent(new Event("input", { bubbles: true }));
    }
  }

  preventScroll(e) {
    e.preventDefault();
    return false;
  }

  async handleList(e, row) {
    const { currentTarget } = e;

    const getListItem = async (itemId, project) => {
      const find = { _id: ObjectId(itemId) };
      const options = { projection: project };

      try {
        await WorkspaceController.getDb({ collection: this.collection });
      } catch {
        (err) => {
          console.error("Error connecting to the product db:", err);
        };
      }

      try {
        const item = await WorkspaceController.getDoc(find, options);
        return item;
      } catch (err) {
        console.error(err.message);
      }
    };

    const itemId = row.dataset._id;

    const activeList = currentTarget.dataset.collection;
    const body = currentTarget.closest("BODY");

    const dynamicModal = body.querySelector("#dynamic-modal");
    const listModal = dynamicModal.querySelector(`#${activeList}-list-modal`);

    const openListModal = () => {
      dynamicModal.classList.add("active");
      listModal.classList.add("active");
    };

    const disableScroll = () => {
      const passive = { passive: false };
      body.addEventListener("DOMMouseScroll", this.preventScroll, false);
      body.addEventListener("wheel", this.preventScroll, passive);
      body.addEventListener("touchmove", this.preventScroll, passive);
    };

    disableScroll();

    const loadListItemDetails = async () => {
      const fieldItems = listModal.querySelectorAll(`.group-item.child`);
      const getListFields = (items) => {
        return Array.from(items).map((item) => ({
          [camelCase(item.dataset.field)]: 1,
        }));
      };

      let listFields = Object.assign({}, ...getListFields(fieldItems));
      listFields.currency = 1;
      listFields.productComponents = 1;

      const fieldsToConvert = ["supplierCost"];
      const listItem = await getListItem(itemId, listFields);

      const formatItem = convertCurrency(
        listItem,
        this.exchangeRates,
        fieldsToConvert,
      );

      for (const item of fieldItems) {
        let key = camelCase(item.dataset.field);
        let value = formatItem[key];

        if (key.includes("Cost")) {
          value = formatCurrency(this.homeCurrency, value, this.homeCurrency);
        }
        item.textContent = value;
      }
      return listItem;
    };

    const listItem = await loadListItemDetails();
    console.log("listItem", listItem);

    const { loadList } = await import("../js/user/items.js");
    await loadList(activeList, listItem);

    openListModal();
  }

  setListItemDetails() {}

  //Controls the list checkboxes:
  async controlCheckInputs(collection, listItem, list) {
    //Targets component list checkboxes:
    const addCheckBoxes = list.querySelectorAll(".add-list-cb");

    const itemId = { _id: listItem._id };

    let updateComponent = {};

    addCheckBoxes.forEach((checkbox) => {
      //Adds event listeners to checkboxes:
      checkbox.addEventListener("change", async (e) => {
        const row = checkbox.closest("tr");
        const checker = checkbox.closest(".checker");

        const compInputQty = row.querySelector(".num-input");
        const minus = row.querySelector(".minus");

        //Targets the Id of the component to add/remove:
        const compId = row.dataset._id;
        //Adds the checked/unchecked to the updateComp obj:
        updateComponent.compId = compId;

        let updateValid = false;

        if (checkbox.checked) {
          compInputQty.value = 1;
          updateComponent.compQty = 1;
          minus.disabled = false;
          updateValid = true;
          checker.classList.add("active");
        } else if (!checkbox.checked) {
          compInputQty.value = 0;
          updateComponent.compQty = 0;
          minus.disabled = true;
          updateValid = true;
          checker.classList.remove("active");
        }

        if (updateValid) {
          try {
            await WorkspaceController.updateComponents(itemId, updateComponent);
          } catch (err) {
            console.error(err.message);
          }
        }
      });
    });

    //Pulls the existing components array:
    const productComponents = listItem?.productComponents ?? [];

    const checkActiveBoxes = () => {
      //Checks checkboxes on modal open:
      addCheckBoxes.forEach((checkbox) => {
        //Targets the Id of the component to add/remove:
        const row = checkbox.closest("tr");
        const checker = checkbox.closest(".checker");

        const compId = row.dataset._id;

        productComponents.forEach((component) => {
          //Checks if any components are associated with products:
          const isChecked = component?.compId.includes(compId);
          if (isChecked) {
            checkbox.checked = true;
            checker.classList.add("active");

            const rowId = checkbox.id.split("-")[0];

            const rowInput = row.querySelector(`#${rowId}-num-input`);
            const minus = row.querySelector(".minus");
            rowInput.value = component.compQty;
            minus.disabled = false;
          }
        });
      });
    };

    checkActiveBoxes(productComponents);
  }

  async controlNumInputs(collection, listItem, list) {
    const minusBtns = list.querySelectorAll(".minus");
    let input, compQty;

    const itemId = { _id: listItem._id };

    let updateComponent = {};
    let updateValid = false;

    minusBtns.forEach((minus) => {
      minus.addEventListener("click", async (e) => {
        const row = minus.closest("tr");
        const checkbox = row.querySelector(".add-list-cb");
        const checker = checkbox.closest(".checker");

        const compId = row.dataset._id;
        input = minus.parentElement.children[0];
        compQty = input.value - 1;
        input.value = compQty;

        //Adds the checked/unchecked to the updateComp obj:
        updateComponent.compId = compId;
        updateComponent.compQty = compQty;

        if (compQty > 0) {
          updateValid = true;
        } else if (compQty == 0) {
          minus.disabled = true;
          checkbox.checked = false;
          checker.classList.remove("active");

          updateValid = true;
        }

        if (updateValid) {
          await WorkspaceController.updateComponents(itemId, updateComponent);
        }
      });
    });

    updateValid = false;

    const plusBtns = list.querySelectorAll(".plus");
    plusBtns.forEach((plus) => {
      plus.addEventListener("click", async (e) => {
        const row = plus.closest("tr");
        const checkbox = row.querySelector(".add-list-cb");
        const checker = checkbox.closest(".checker");

        const compId = row.dataset._id;
        input = plus.parentElement.children[0];
        compQty = Number(input.value) + 1;
        input.value = compQty;

        updateComponent.compId = compId;
        updateComponent.compQty = compQty;

        if (compQty == 1) {
          updateValid = true;
          const minus = plus.previousElementSibling;
          minus.disabled = false;
          checkbox.checked = true;
          checker.classList.add("active");
        } else if (compQty > 1) {
          updateValid = true;
        }

        if (updateValid) {
          await WorkspaceController.updateComponents(itemId, updateComponent);
        }
      });
    });

    updateValid = false;

    const inputs = list.querySelectorAll(".num-input");
    inputs.forEach((input) => {
      input?.setAttribute("maxlength", 3);
      input.addEventListener("change", async (e) => {
        const row = input.closest("tr");
        const compId = row.dataset._id;
        const checkbox = row.querySelector(".add-list-cb");
        const checker = checkbox.closest(".checker");

        //Adds the checked/unchecked to the updateComp obj:
        updateComponent.compId = compId;

        const minus = input.nextElementSibling;
        if (checkbox.checked && input.value < 0) {
          input.value = 0;
          compQty = 0;
          minus.disabled = true;
          checkbox.checked = false;
          checker.classList.remove("active");

          updateValid = true;
        } else if (checkbox.checked && input.value.length == 0) {
          input.value = 0;
          compQty = 0;
          minus.disabled = true;
          checkbox.checked = false;
          checker.classList.remove("active");

          updateValid = true;
        } else if (checkbox.checked && input.value == 0) {
          compQty = 0;
          checkbox.checked = false;

          checker.classList.remove("active");
          minus.disabled = true;
          updateValid = true;
        } else if (!checkbox.checked && input.value > 0) {
          compQty = input.value;
          checkbox.checked = true;
          checker.classList.add("active");
          minus.disabled = false;
          updateValid = true;
        } else if (checkbox.checked && input.value > 0) {
          compQty = input.value;
          updateValid = true;
        }

        if (updateValid) {
          updateComponent.compQty = compQty;
          await WorkspaceController.updateComponents(itemId, updateComponent);
        }
      });
    });
  }

  //Handles server response:
  handleInsertedRow(newItem) {
    this.values.unshift(newItem);
    this.insertRows([newItem], 1);
    this.handleFillInRows();
  }

  //Attached to eventListner:
  async saveUpdatedRow(e, row) {
    const productId = row.dataset._id;
    const collection = document.body.dataset.collection;

    const _id = { key: "_id", value: ObjectId(productId) };

    const dataIndex = row.rowIndex - 1;
    let originalData = this.values[dataIndex];

    const inputs = row.querySelectorAll(".capture.is-valid");

    let datasets = Array.from(inputs).map(({ dataset }) => dataset);
    datasets = [...datasets, _id];

    let params = {
      key: collection,
      data: datasets,
    };

    console.log("updateItemParams", params);

    let result;

    try {
      result = await updateItem(params);
    } catch (err) {
      console.error(err);
    }

    if (!result?.modifiedCount) {
      const values = { row: row, original: originalData };
      this.resetOrUpdateRows(values);
    }

    this.insertColorMessages(row);
    this.handleRowBtns(row);
  }

  //Handles server response:
  handleUpdate(id, update) {
    const updateRow = () => {
      const rowUpdate = Object.assign({}, update);

      const rows = Array.from(this.table.rows);

      //Finds the updated row:
      id = id.toString();
      const row = rows.find((row) => row.dataset._id === id);

      //Finds to original doc:
      const original = this.values.find((d) => d._id.toString() === id);
      const values = { row: row, update: rowUpdate, original: original };

      this.resetOrUpdateRows(values);
    };

    const updateList = () => {
      const componentsList = dqs("#components-list-modal.active");

      if (componentsList) {
        let listUpdate = Object.assign({}, update);

        const fieldsToConvert = ["supplierCost"];
        const keys = Object.keys(listUpdate);

        if (keys.find((k) => fieldsToConvert.includes(k))) {
          listUpdate = convertCurrency(
            listUpdate,
            this.exchangeRates,
            fieldsToConvert,
          );
        }

        for (const key in listUpdate) {
          let field = kababCase(key);
          let updateField = componentsList.querySelector(
            `[data-field="${field}"]`,
          );

          let formatted;
          if (key.includes(fieldsToConvert)) {
            formatted = formatCurrency(this.homeCurrency, listUpdate[key]);
          } else {
            formatted = listUpdate[key];
          }

          if (updateField) updateField.textContent = formatted;
        }
      }
    };

    updateRow();
    updateList();

    this.values = this.values.map((d) => {
      if (d._id.toString() === id) {
        for (const key in update) {
          d[key] = update[key];
        }
      }
      return d;
    });
  }

  //Attached to eventListner:
  async deleteRow(e, row) {
    //Confirm delete modal:
    const deleteModal = dqs(`#delete-item-modal`);
    const delForm = dqs(`#del-item-form`);
    const delItemDets = dqs("#delete-doc-strip");

    let deleteItem = {};
    let _id, name, ref;
    _id = row.dataset._id;

    let query = { _id: ObjectId(_id) };
    let project = {
      projection: {
        itemName: 1,
        supplierName: 1,
        sku: 1,
        supplierId: 1,
      },
    };

    try {
      await WorkspaceController.getDb({ collection: this.collection });
      deleteItem = await WorkspaceController.getDoc(query, project);
    } catch (err) {
      console.error(err.message);
    }

    const { itemName, supplierName, sku, supplierId } = deleteItem;

    name = itemName || supplierName;
    ref = sku || supplierId;

    //Fills in the confirmation cell & displays the delete modal:
    delItemDets.textContent = `${ref}: ${name}`;
    deleteModal.classList.add("active");
    delForm.dataset._id = _id;
    delForm.dataset.collection = this.collection;
  }

  //Handles server response:
  async handleDelete(id) {
    const rows = Array.from(this.table.rows);

    console.log("handleDelete/id", id);
    //Finds the updated row:
    id = id.toString();
    const deleteRow = rows.find((row) => row.dataset._id === id);
    const rowIndex = deleteRow.rowIndex;

    this.table.deleteRow(rowIndex);

    const pages = dqa(".pagination-number");
    const pageCount = pages.length;

    const currentPage = dqs(".pagination-number.active");
    const pageIndex = Number(currentPage.getAttribute("page-index"));

    const getRowItem = async () => {
      const sortWrapper = dqs(`#sort-${this.type}-wrapper`);
      const sortOptions = sortWrapper.querySelectorAll("INPUT:checked");
      const sortButton = sortWrapper.querySelector(".sort-btn.asc.active");

      const rowCount = rows.length - 2;
      const skipPosition = rowCount * pageIndex;

      let sortBy = { sku: -1 };
      const sortAs = sortButton ? 1 : -1;
      for (const option of sortOptions) {
        const key = camelCase(option.value);
        if (option) sortBy[key] = sortAs;
      }

      const collection = this.collection;
      const fields = this.activeFields;
      const sort = { $sort: sortBy };
      const skip = { $skip: skipPosition };
      const limit = { $limit: 1 };

      let query = { collection, fields, sort, skip, limit };
      const doc = await WorkspaceController.queryTableData(query);
      return doc[0];
    };

    if (pageIndex !== pageCount) {
      let item = await getRowItem();
      if (item) this.insertRows([item], rows.length - 1);
    }

    this.handleFillInRows();
  }

  //Attached to eventListner:
  cancelRow(e) {
    const { currentTarget } = e;
    const row = currentTarget.closest("tr");

    const dataIndex = row.rowIndex - 1;
    const originalData = this.values[dataIndex];

    const values = { row: row, original: originalData };
    this.resetOrUpdateRows(values);
    this.insertColorMessages(row);
    this.handleRowBtns(row);
  }

  resetOrUpdateRows({ row, update = null, original } = {}) {
    const formatData = (key, data) => {
      const isString = (value) => typeof value === "string";
      switch (key) {
        case "colors":
          data[key] = isColorCell(data[key]);
          break;
        case "supplierType":
          data[key] = isSupplierTypeCell(data[key]);
          break;
        case "country":
        case "supplier":
        case "sizes":
          if (data[key]?.value) {
            data[key] = data[key]?.value;
          }
          break;
        case "addressTwo":
          data[key] = data[key] || "...";
          break;
        default:
          if (key.includes("Cost")) {
            setCurrency(this.homeCurrency, data);
          }
          const field = kababCase(key);
          const systemField = this.fields?.system?.includes(field);
          if (systemField) {
            data[key] = `<span class="immutable-item">${data[key]}</span>`;
          }
          break;
      }

      if (isString(data[key])) data[key] = data[key].trim();

      return data;
    };

    const setOriginalValues = Object.assign({}, original);

    const cells = row.querySelectorAll("TD:not(.edit-cell)");

    cells.forEach((cell) => {
      const key = camelCase(cell.dataset.key);

      const contextual = cell.querySelector(".sub-text, .immutable-icon");

      let updateOriginal = formatData(key, setOriginalValues);

      if (update) {
        if (update[key]) {
          update = formatData(key, update);
          //Inserts the updated values back into the table:
          cell.innerHTML = update[key];
        } else {
          //Inserts the original values back into the table:
          cell.innerHTML = updateOriginal[key];
        }
      } else {
        //Inserts the original values back into the table:

        cell.innerHTML = updateOriginal[key];
      }

      if (contextual) {
        cell.insertAdjacentElement("beforeend", contextual);
      }
    });
  }

  handleFillInRows() {
    const totalRows = this.table.rows.length - 1; //Excluding the header.
    const totalCells = this.activeFields.length;
    const cell = `<td></td>`;
    const required = this.limit - totalRows;
    const fillInRow = this.table.querySelector(".fill-in-row");

    //Adds fill ins:
    if (required > 0) {
      let rows = "";
      let cells = "";
      for (let i = 0; i < totalCells; i++) cells = cells + cell;
      const row = `<tr class="fill-in-row">${cells}</tr>`;
      for (let i = 0; i < required; i++) rows = rows + row;
      this.table.insertAdjacentHTML("beforeend", rows);
    } else if (fillInRow) {
      //Removes fill ins:
      fillInRow.remove();
    }
  }
}

export class TableController extends TableBuilder {
  constructor({
    collectionFields,
    collection,
    type,
    fields,
    activeFields,
    defaultViews,
    savedViews,
    options,
  } = {}) {
    super();
    this.collectionFields = collectionFields;
    this.collection = collection;
    this.type = type;
    this.fields = fields;
    this.activeFields = activeFields;
    this.defaultViews = defaultViews;
    this.savedViews = savedViews;
    this.options = options;
  }

  insertCollectionControls() {
    const collectionWrapper = dqs("#table-collection-wrapper");
    const fields = [...this.collectionFields, "spacer"];

    fields.forEach((collection) => {
      let id = kababCase(collection);
      let name = sentanceCase(collection);
      let enabled = "";

      if (id !== "spacer") enabled = "enabled";

      collectionWrapper.insertAdjacentHTML(
        "beforeend",
        `<div data-collection="${id}" id="${id}-collection-tab" class="collection-tab ${enabled}"><span>${name}</span></div>`,
      );
    });
  }

  insertQueryControls() {
    let id = kababCase(this.type);
    const queryWrapper = dqs(`#${id}-query-wrapper`);
    const buttonText = removePlural(this.collection);

    let button = "";
    let search, dropdowns;

    if (this.type === "table") {
      //New item button:
      button = `<div class="button-wrapper">
          <button aria-label="add-new-${this.collection}" class="new-item-btn">
            <a class="button-text">
              ${pageIcons.plusIcon}
              <span id="new-item-copy">${buttonText}</span>
            </a>
          </button>
        </div>`;
    }

    //Table search input:
    search = `<div class="search-wrapper">
          <input
            type="search"
            id="${this.type}-search-input"
            class="search-input"
            placeholder="Search ${this.collection}..."
          />
        </div>`;

    //Filter & sort wrappers:
    dropdowns = `<div class="filter-sort-wrapper">
          <div class="select-filter dropdown-wrapper">
            <button aria-label="filter-items" id="filter-${this.type}-btn" type="button" class="filter-dropdown dropdown-btn">Filter</button>
            <div id="filter-${this.type}-options" class="dropdown-options options-wrapper"></div>
          </div>
          <div class="select-filter dropdown-wrapper">
            <button aria-label="sort-items" id="sort-${this.type}-btn" type="button" class="filter-dropdown dropdown-btn">Sort</button>
            <div id="sort-${this.type}-wrapper" class="dropdown-options options-wrapper">
              <div id="sort-${this.type}-options" class="sort-options"></div>
              <div class="sort-btns">
                <button aria-label="sort-ascending" id="sort-${this.type}-asc" type="button" class="sort-btn asc" value="1">Ascending</button>
                <button aria-label="sort-descending" id="sort-${this.type}-desc" type="button" class="sort-btn desc" value="-1" >Descending</button>
              </div>
            </div>
          </div>
        </div> `;

    //Inserts all table query controls:
    const queryControls = button + search + dropdowns;
    queryWrapper.innerHTML = queryControls;

    //Opens the new item menu:
    const newItemBtn = dqs(".new-item-btn");

    if (this.type === "table") {
      newItemBtn.addEventListener("click", (e) => {
        e.preventDefault();

        const dynamicModal = dqs("#dynamic-modal");
        const currentModal = dqs(`#new-item-modal`);
        const inputs = currentModal.querySelectorAll("INPUT");
        inputs[0]?.focus();

        dynamicModal.classList.add("active");
        currentModal.classList.add("active");
        newItemBtn.classList.add("active");
      });
    }

    const filterSortMenu = queryWrapper.querySelector(".filter-sort-wrapper");

    //Controls dropdown filters & Sorts:
    const dropdownButtons = filterSortMenu.querySelectorAll(".dropdown-btn");
    dropdownButtons.forEach((dropdown) => {
      dropdown.addEventListener("click", (e) => {
        e.preventDefault();
        dropdown.classList.toggle("active");
      });
    });
  }

  insertQueryOptions() {
    //Inserts the table filters:
    const filterFields = this.fields.filter;
    this.insertTableFilter(filterFields);

    //Inserts the table sorts:
    const sortFields = [...this.fields.text, ...(this.fields.system || [])];
    sortFields.sort();
    this.insertTableSort(sortFields);
  }

  insertViewControls() {
    const paginationWrapper = dqs("#table-pagination-wrapper");
    const saveViewInput = `
    <input
      id="save-view"
      name="save-view"
      class="submit-input capture"
      type="text"
      placeholder="Enter view name..."
      maxlength="15"
      required
      autocomplete="off"
      name="notASearchField"
    />`;

    const submitButtons = `<div class="submit-icons submit-wrapper">
    <button aria-label="submit-view" type="submit" class="view-icon" value="add" disabled>${pageIcons.saveIcon}</button>
    <button aria-label="reset-view" type="reset" class="view-icon" value="reset">${pageIcons.cnxIcon}</button>
    </div>`;

    const views = `<div class="views-wrapper dropdown-wrapper">
          <button aria-label="table-views" id="table-views-btn" class="view-dropdown dropdown-btn">Table views</button>
          <div id="table-view-opts" class="dropdown-options options-wrapper">
            <div class="view-tabs-wrapper">
              <div id="default-views-tab" class="view-tab active">Default views</div>
              <div id="saved-views-tab" class="view-tab">Saved views</div>
            </div>
            <div id="table-views">
              <div id="default-views" class="view-tab-controls active"></div>
              <div id="saved-views" class="view-tab-controls"></div>
            </div>
            <div id="column-views"></div>
            <button aria-label="save-view" id="save-view-button" class="view-button active">${pageIcons.plusIcon} Save view</button>
            <button aria-label="delete-view" id="delete-view-button" class="view-button"> ${pageIcons.minusIcon} Delete view</button>
            <form id="table-view-form" ><div class="input-wrapper">${saveViewInput}${submitButtons}</div></form>
          </div>
        </div>
    </div>`;

    paginationWrapper.innerHTML = views;

    const viewInput = dqs("#save-view");
    const viewForm = dqs("#table-view-form");

    const validateParams = {
      form: viewForm,
      inputs: [viewInput],
      validate: "any",
      conditions: this.conditions,
    };

    const validateInputs = new ValidationController(validateParams);
    validateInputs.handleValidation({ position: "left" });

    const options = {
      position: "left",
      element: viewInput,
      message: null,
      eventTypes: ["mongoError"],
    };

    const calloutMesage = new CalloutController(options);
    calloutMesage.insertCalloutMessage();
    calloutMesage.setCalloutEvent();

    //Controls all pagination dropdowns:
    const dropdownButtons = paginationWrapper.querySelectorAll(".dropdown-btn");
    dropdownButtons.forEach((dropdown) => {
      dropdown.addEventListener("click", (e) => {
        e.preventDefault();
        dropdown.classList.toggle("active");
      });
    });
  }

  insertColumnViews() {
    const columnViewsWrapper = dqs("#column-views");
    columnViewsWrapper.innerHTML = "";

    let columns = [...this.activeFields];

    columns.sort();

    columns.forEach((column) => {
      let name = this.formatHeaders(column);
      let checkbox = `<div class="checker"><label><input class="${this.type}-cb" name="${column}" type="checkbox" value="${column}" autocomplete="off"/><span class="checkmark"></span><span>${name}</span></label></div>`;
      columnViewsWrapper.insertAdjacentHTML("beforeend", checkbox);
    });
  }

  insertTableViews() {
    const viewsWrappers = dqa(`.view-tab-controls`);
    viewsWrappers.forEach((tabBody) => {
      tabBody.innerHTML = "";
    });

    this.constructor.insertViews("default", this.type, this.defaultViews);
    this.constructor.insertViews("saved", this.type, this.savedViews);
  }

  static insertViews(key, type, views) {
    type = kababCase(type);
    const viewsTab = dqs(`#${key}-views-tab`);
    const viewsWrapper = dqs(`#${key}-views`);

    if (views.length) {
      views.forEach(({ viewName, viewId } = {}) => {
        let name = sentanceCase(viewName);
        let value = camelCase(viewName);
        viewId = viewId.toString();

        let dataset = `data-id="${viewId}"`;
        let checkbox = `<div class="checker"><label>
        <input class="${type}-cb" type="checkbox" ${dataset} value="${value}" name="${value}"/>
        <span class="checkmark views"></span><span>${name}</span></label></div>`;
        viewsWrapper.insertAdjacentHTML("beforeend", checkbox);
      });
      viewsTab.classList.add("enabled");
    } else {
      viewsTab.classList.remove("enabled");
    }
  }

  insertPaginationControls() {
    const paginationWrapper = dqs("#table-pagination-wrapper");

    const pagination = `
    <div class="pagination-wrapper">
      <button aria-label="previous-page" class="pagination-button"
        id="prev-button"
        aria-label="Previous page"
        title="Previous page">
        <div class="prev-arrow"></div>
      </button>
      <div id="pagination-numbers"></div>
      <button aria-label="next-page" class="pagination-button"
        id="next-button"
        aria-label="Next page"
        title="Next page">
        <div class="next-arrow"></div>
      </button>
      <a id="row-count"></a>
    </div>`;

    paginationWrapper.insertAdjacentHTML("beforeend", pagination);
  }

  //Creates the filter checkboxes for both Tables & Lists:
  insertTableFilter(fields) {
    fields.sort();

    const filterWrapper = dqs(`#filter-${this.type}-options`);
    filterWrapper.innerHTML = "";

    //Array of size bridge options:
    const options = { ...this.options };
    const checkboxes = this.fields.checkbox;

    if (!filterWrapper.hasChildNodes()) {
      fields.forEach((field) => {
        const filterName = sentanceCase(field);
        let key = camelCase(field);

        //Inserts the dropdowns that control each filter type:
        filterWrapper.insertAdjacentHTML(
          "beforeend",
          `
          <div class="collapse-wrapper">
            <button aria-label="filter-by-${field}" id="${field}-button" type="button" class="sub-collapse dropdown-btn">${filterName}</button>
            <div id="${field}-checkboxes" class="sub-options-wrapper"></div>
          </div>
          `,
        );

        const optionsWrapper = filterWrapper.querySelector(
          `#${field}-checkboxes`,
        );

        if (options[key]) {
          options[key].forEach((option) => {
            //Exceptions for sizes:
            let value, dataset;

            if (typeof option === "string") {
              value = option;

              dataset = `data-filter-key="${key}"`;
            } else {
              value = option?._id || option?.code;
              option = option.value;
              let filterKey = "";

              if (key === "country") {
                filterKey = `address.${key}.code`;
              } else {
                filterKey = `${key}._id`;
              }

              dataset = `data-key="${key}" data-filter-key="${filterKey}"`;
            }

            const checkbox = `<div class="checker"><label>
          <input data-table-type="${this.type}" ${dataset} name="${value}" type="checkbox" value="${value}" aria-label="${option}" />
          <span class='checkmark'></span>
          ${option}<span class="count"></span></label></div>`;

            optionsWrapper.insertAdjacentHTML("beforeend", checkbox);
          });
        }
      });

      filterWrapper.insertAdjacentHTML(
        "beforeend",
        `<div class="submit-wrapper submit-buttons"><button aria-label="reset-filters" name="reset" class="btn filters">Reset filters</button></div>`,
      );
      const resetButton = filterWrapper.querySelector(`[name="reset"].filters`);
      resetButton.addEventListener("click", this.handleFilterReset);
    }
  }

  insertTableSort(options) {
    const sortOptions = dqs(`#sort-${this.type}-options`);
    sortOptions.innerHTML = "";

    options.forEach((option) => {
      const checkbox = `<div class="checker"><label>
      <input data-table-type="${
        this.type
      }" type="checkbox" value="${option}" name="${option}" aria-label="${option}" autocomplete="off"/>
      <span class="checkmark"></span>
      <span>${sentanceCase(option)}</span></label></div>`;

      sortOptions.insertAdjacentHTML("beforeend", checkbox);
    });
  }

  handleFilterReset(e) {
    const { currentTarget } = e;

    const wrapper = currentTarget.closest(".options-wrapper");
    const checkedBoxes = wrapper.querySelectorAll("INPUT:checked");

    checkedBoxes.forEach((checkbox) => {
      checkbox.click();
    });
  }

  static handleActiveFilters(type, filters) {
    const { original, filtered } = Object.assign({}, filters);
    const filterOptions = dqs(`#filter-${type}-options`);
    const inputs = filterOptions.querySelectorAll("input");

    const matchAll = (filter, origin) =>
      origin.every((originItem) => filter.includes(originItem));

    const matchEach = (filter, origin) =>
      matchAll(filter, origin) && matchAll(origin, filter);

    const matchFiltered = (filter, oId) => {
      return filter.find(({ key: fKey } = {}) => {
        let fId = fKey?._id?.toString() || fKey?.code || fKey;

        if (Array.isArray(fKey)) {
          return matchEach(fId, oId);
        } else {
          return fId === oId;
        }
      });
    };

    const mergeFilters = (filter, origin) => {
      return origin.reduce((acc, { key: oKey, count: oCount = 0 } = {}) => {
        let oId = oKey?._id?.toString() || oKey?.code || oKey;

        const match = matchFiltered(filter, oId);
        const fCount = match?.count || 0;

        let fil = {},
          org = {};

        if (Array.isArray(oKey)) {
          oId.forEach((k) => {
            fil = { filtered: acc[k]?.filtered + fCount || fCount };
            org = { original: acc[k]?.original + oCount || oCount };
            acc[k] = { ...fil, ...org };
          });
        } else {
          fil = { filtered: acc[oId]?.filtered + fCount || fCount };
          org = { original: acc[oId]?.original + oCount || oCount };
          acc[oId] = { ...fil, ...org };
        }
        return { ...acc };
      }, {});
    };

    let mergedFilters = {};

    if (Object.keys(original).length) {
      for (const parent in original) {
        mergedFilters[parent] = mergeFilters(
          filtered[parent],
          original[parent],
        );
      }

      for (const input of inputs) {
        const { value, dataset } = input;

        let key = dataset.key || dataset.filterKey;

        let checker = input.closest(".checker");
        let count = checker.querySelector(".count");

        const group = mergedFilters[key];
        const match = group[value];

        //Removes any filters without values:
        if (!match && key === "country") {
          checker.remove();
        } else {
          //Assigns a quatity to each filter:
          if (match?.original === match?.filtered) {
            count.innerHTML = `(${match?.original || 0})`;
          } else {
            count.innerHTML = `( <b>${match?.filtered}</b> / ${match?.original})`;
          }
        }
      }
    }

    return mergedFilters;
  }
}

//All the below functions alter the incoming data to fit cell requirements:
const isColorCell = (cellData) => {
  let colors = [];
  if (cellData?.includes("span")) {
    colors.push(cellData);
  } else {
    cellData.forEach(({ value } = {}) => {
      colors.push(
        `<span id="color-${value.toLowerCase()}" class='color-box' style='background-color:var(--${value.toLowerCase()});'></span>`,
      );
    });
  }

  cellData = colors.join("");
  return cellData;
};

const isSupplierTypeCell = (cellData) => {
  let types = [];
  if (cellData?.includes("span")) {
    types.push(cellData);
  } else {
    cellData.forEach((type) => {
      types.push(
        `<span id="type-${type.toLowerCase()}" class="type-box">${type}</span>`,
      );
    });
  }

  cellData = types.join("");
  return cellData;
};

const isEditCell = (fields) => {
  let hasComponentsBtn = "";

  const systemFields = fields?.system || [];

  if (systemFields.includes("component-costs")) {
    hasComponentsBtn = `<button aria-label="add-components" data-collection="components" class="comp-btn row-btn active">${pageIcons.plusIcon}</button>`;
  }

  return `<div class="row-btns-wrapper">
  ${hasComponentsBtn}
  <button aria-label="edit-item" class="edit-btn row-btn active">${pageIcons.editIcon}</button>
  <button aria-label="delete-item" class="del-btn row-btn active">${pageIcons.deleteIcon}</button>
  <button type="submit" aria-label="save-edit" class="sve-btn row-btn" disabled>${pageIcons.saveIcon}</button>
  <button type="reset" aria-label="cancel-edit" class="cnx-btn row-btn">${pageIcons.cnxIcon}</button>
 </div>`;
};

const isLeadTimeCell = () => {
  return `<span class="sub-text"> wks</span>`;
};

const isAddComponentCell = (rowIndex) => {
  return `<div class="checker">
     <label>
      <input id="r${rowIndex}-checkbox" name="add-component" class="add-list-cb" type="checkbox" value="true" />
      <span class="checkmark"></span>
    </label>
  </div>`;
};

const isComponentQtyCell = (rowIndex) => {
  return `<div class="num-input-wrapper">
    <input id="r${rowIndex}-num-input" class="num-input" name="add-quantity" type="number" value="0" />
    <button aria-label="minus-quantity" class="minus num-btn" disabled>${pageIcons.minusIcon}</button>
    <button aria-label="plus-quantity" class="plus num-btn">${pageIcons.plusIcon}</button>
  </div>`;
};
