"use strict";
import { app } from "./auth.mjs";
import { BSON } from "realm-web";
import { FormBuilder } from "../modules/forms.mjs";
import { dqs } from "../modules/ui.mjs";
import { tableSettings } from "../modules/local.mjs";
const { authFields, conditions } = tableSettings;

const { EJSON } = BSON;
const { deserialize } = EJSON;

let db, databaseRef;

export default class WorkspaceController {
  static async getDb({ database = "resources", collection } = {}) {
    const currentUser = app.currentUser;

    try {
      const mongo = currentUser?.mongoClient("mongodb-atlas");

      db = mongo.db(database);
      databaseRef = db.collection(collection);
    } catch (err) {
      throw err;
    }
  }

  static async getUser() {
    let user;
    try {
      user = app?.currentUser;
    } catch (err) {
      throw err;
    }

    if (user) {
      await user.refreshCustomData();
      const customData = deserialize(user?.customData);
      return customData;
    } else {
      return {};
    }
  }

  static async getDoc(query, options = {}) {
    try {
      //Query must be an array of objs:
      const document = await databaseRef.findOne(query, options);
      // since this method returns the matched document, not a cursor, print it directly
      return document;
    } catch (err) {
      throw ("Error finding doc", err);
    }
  }

  static async getDocs(query, options) {
    try {
      const docs = await databaseRef.find(query, options);
      return docs;
    } catch (err) {
      throw (`Error finding documents:`, err);
    }
  }

  static async queryDocs(pipeline) {
    try {
      let docs = await databaseRef.aggregate(pipeline);
      return docs;
    } catch (err) {
      throw (`Error finding documents:`, err);
    }
  }

  static async getSettings(collection) {
    try {
      const currentUser = app.currentUser;
      const cloudFunc = currentUser?.functions;

      const data = await cloudFunc.cppPullSettings(collection);

      return data;
    } catch (err) {
      throw err;
    }
  }

  static async getTableData(collection, query) {
    try {
      const currentUser = app.currentUser;
      const cloudFunc = currentUser?.functions;

      let data = await cloudFunc.cppPullTable(collection, query);

      return data;
    } catch (err) {
      console.log("Error getting product data:", err);
      throw err;
    }
  }

  static async queryTableData(query) {
    try {
      const currentUser = app.currentUser;
      const cloudFunc = currentUser?.functions;

      let data = await cloudFunc.cppQueryTable(query);
      return data;
    } catch (err) {
      throw (`Error finding documents:`, err);
    }
  }

  static async insertDoc(item) {
    const user = app.currentUser.profile;

    try {
      item.info = { createdBy: user.email };
      item.info.dateCreated = new Date();

      return await databaseRef.insertOne(item, { w: "majority" });
    } catch (err) {
      throw ("Error item was not inserted:", err);
    }
  }

  static async updateDoc(query, updateItem, updateOpts) {
    try {
      let update = { $set: { ...updateItem } };

      const result = await databaseRef.updateOne(query, update, updateOpts);
      console.log(
        `${result.matchedCount} items(s) matched the query, updated ${result.modifiedCount} items(s)`,
      );
      return result;
    } catch (err) {
      throw ("Error doc was not updated:", err);
    }
  }

  static async deleteDoc(query) {
    try {
      return await databaseRef.deleteOne(query);
    } catch (err) {
      throw ("Error doc was not deleted:", err);
    }
  }

  static async insertItem(query) {
    const cloudFunc = app.currentUser.functions;

    try {
      return await cloudFunc.cppCreateItem(query);
    } catch (err) {
      const error = EJSON.parse(err.error);
      throw error;
    }
  }

  static async updateItem(query) {
    const cloudFunc = app.currentUser.functions;

    try {
      return await cloudFunc.cppUpdateItem(query);
    } catch (err) {
      const error = EJSON.parse(err.error);
      throw error;
    }
  }

  static async updateComponents(docId, compObj) {
    const cloudFunc = app.currentUser.functions;

    try {
      const result = await cloudFunc.cppUpdateProductComponents(docId, compObj);

      return result;
    } catch (err) {
      throw ("Error updating product components:", err);
    }
  }

  static async updateLandedCost(docId) {
    const cloudFunc = app.currentUser.functions;
    try {
      const result = await cloudFunc.cppUpdateLandedCost(docId);
      return result;
    } catch (err) {
      throw ("Error updating the landed cost price:", err);
    }
  }

  static async updateUserSettings(query) {
    //Used for user and workspace settings:
    const cloudFunc = app.currentUser.functions;

    try {
      return await cloudFunc.cppUpdateUserSettings(query);
    } catch (err) {
      const error = EJSON.parse(err.error);
      throw error;
    }
  }

  static async updateTypeSettings(query) {
    //Used for types/views:
    const cloudFunc = app.currentUser.functions;
    try {
      return await cloudFunc.cppUpdateTypeSettings(query);
    } catch (err) {
      const error = EJSON.parse(err.error);
      throw error;
    }
  }

  static async updateSizeSettings(query) {
    //Used for user and workspace settings:
    const cloudFunc = app.currentUser.functions;

    try {
      return await cloudFunc.cppUpdateSizeSettings(query);
    } catch (err) {
      const error = EJSON.parse(err.error);
      throw error;
    }
  }

  static async updateWorkSpaceSettings(query) {
    //Used for user and workspace settings:
    const cloudFunc = app.currentUser.functions;

    try {
      return await cloudFunc.cppUpdateWorkspaceSettings(query);
    } catch (err) {
      const error = EJSON.parse(err.error);
      throw error;
    }
  }

  static async updateCurrencySettings(query) {
    //Used for user and workspace settings:
    const cloudFunc = app.currentUser.functions;

    try {
      return await cloudFunc.cppUpdateCurrencySettings(query);
    } catch (err) {
      const error = EJSON.parse(err.error);
      throw error;
    }
  }

  static async updateDocs(query, updateData, options) {
    try {
      const result = await databaseRef.updateMany(query, updateData);
      console.log(`Updated ${result.modifiedCount} documents`);
    } catch (err) {
      throw err;
    }
  }
}

export class WatchWorkspace {
  constructor({ database = "resources", collection, editor }) {
    this.database = database;
    this.collection = collection;
    this.connection = {};
    this.editor = editor;
    this.stream_ids = [];
    this.updateStream = {};
    this.insertStream = {};
  }

  async loadWatch() {
    const currentUser = app.currentUser;

    try {
      const mongo = currentUser?.mongoClient("mongodb-atlas");

      db = mongo.db(this.database);
      this.connection = db.collection(this.collection);
    } catch (err) {
      throw err;
    }
  }

  setProjection(doc) {
    let address = doc?.address;
    if (address) {
      delete doc.address;
      doc = { ...doc, ...address };
    }
    return doc;
  }

  async watchUpdates({ _ids }) {
    if (!Object.keys(this.connection).length) {
      return;
    }

    if (Object.keys(this.updateStream).length) {
      this.updateStream.return();
    }

    this.stream_ids = _ids;
    this.updateStream = this.connection.watch({ ids: this.stream_ids });

    const convertDelimitedObject = (obj, modify) => {
      let i,
        j,
        keys,
        ref,
        result = modify ? obj : {};
      for (i in obj) {
        for (keys = i.split("."), ref = result, j = 0; j < keys.length - 1; j++)
          ref = ref[keys[j]] = ref[keys[j]] || {};
        ref[keys[j]] = obj[i];
        if (modify && j) delete obj[i];
      }
      return result;
    };

    for await (const change of this.updateStream) {
      const { documentKey, fullDocument, updateDescription } = change;
      const id = documentKey._id;

      console.log("operationType", change.operationType);
      switch (change.operationType) {
        case "update": {
          let update = updateDescription?.updatedFields;
          update = convertDelimitedObject(update);
          let fields = Object.keys(update);
          let isCost = fields.find((item) => item.includes("Cost"));

          console.log(`updated document: ${id}`, update);

          //Adds the currency where a conversion may be required:
          if (isCost) {
            update.currency = fullDocument.currency;
          }

          if (update?.info) {
            delete update.info;
          }

          update = this.setProjection(update);

          this.editor.handleUpdate(id, update);
          break;
        }
        case "replace": {
          console.log(`replaced document: ${id}`, fullDocument);
          break;
        }
        case "delete": {
          console.log(`deleted document: ${id}`);
          this.editor.handleDelete(id);
          break;
        }
      }
    }

    console.log("updateStream", this.updateStream);
    return this.updateStream;
  }

  async watchInserts() {
    const createdBy = app.currentUser.customData.email;

    if (!Object.keys(this.connection).length) {
      return;
    }

    if (Object.keys(this.insertStream).length) {
      this.insertStream.return();
    }

    this.insertStream = this.connection.watch({
      filter: {
        operationType: "insert",
      },
    });

    let _id;
    for await (const change of this.insertStream) {
      const { documentKey, fullDocument } = change;
      let insert = fullDocument;
      _id = documentKey?._id;

      if (_id) {
        if (this.updateStream) {
          this.updateStream.return();
        }

        console.log("stream_ids", this.stream_ids);

        this.watchUpdates({
          _ids: [_id, ...this.stream_ids],
        });
      }

      if (insert?.info) {
        delete insert.info;
      }

      insert = this.setProjection(insert);

      console.log("changeStream/insertedDoc", insert);

      this.editor.handleInsertedRow(insert);
    }

    console.log("watchInserts", this.insertStream);
    return this.insertStream;
  }
}

export class WorkspaceErrors {
  constructor({ errorCode } = {}) {
    this.errorCode = errorCode;
  }

  handleErrors() {
    const authWrapper = dqs("#auth-wrapper");
    const requestModal = dqs("#request-confirmation-modal");
    const loginModal = dqs("#login-modal");

    switch (this.errorCode) {
      case "InvalidSession":
        window.location = "./login.html";
        break;

      case "UserpassTokenInvalid":
        authWrapper.style = "";

        requestModal.style.display = "";

        break;
      case "UserAlreadyConfirmed":
        const loginTitle = loginModal.querySelector(".modal-title");
        const loginMessage = loginModal.querySelector(".modal-message");
        authWrapper.style = "";
        requestModal.style.display = "none";
        loginTitle.textContent = "REGISTRATION CONFIRMED!";
        loginMessage.textContent =
          "Login to complete the registration process.";
        loginModal.style.display = "block";
        break;
      default:
        console.log("Unhandled errorCode:", this.errorCode);
        break;
    }
  }
}

const asyncIntervals = [];

const runAsyncInterval = async (cb, interval, intervalIndex) => {
  await cb();
  if (asyncIntervals[intervalIndex]) {
    setTimeout(() => runAsyncInterval(cb, interval, intervalIndex), interval);
  }
};

const setAsyncInterval = (cb, interval) => {
  if (cb && typeof cb === "function") {
    const intervalIndex = asyncIntervals.length;
    asyncIntervals.push(true);
    runAsyncInterval(cb, interval, intervalIndex);
    return intervalIndex;
  } else {
    throw new Error("Callback must be a function");
  }
};

const clearAsyncInterval = (intervalIndex) => {
  if (asyncIntervals[intervalIndex]) {
    asyncIntervals[intervalIndex] = false;
  }
};

setAsyncInterval(async () => {
  try {
    await WorkspaceController.getUser();
  } catch (err) {
    if (err?.statusCode) {
      const sessionError = new WorkspaceErrors(err);
      sessionError.handleErrors();
    } else {
      if (err.message) {
        console.log(err.message);
      }
    }
  }
}, 1200000);
